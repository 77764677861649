import React from "react"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import Sections from "../components/Sections"

const IndexPage = () => (
  <>
    <SEO title="Inicio" socialTitle="Tooth Club: Aprende Sobre Odontología" />
    <Hero />
    <Sections />
  </>
)

export default IndexPage
