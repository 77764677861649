import React from "react"
//@ts-ignore
import PatternBackground from "../../public/static/line-in-motion.svg"
//@ts-ignore
import Teeth from "../images/teeth.jpg"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

type Category = {
  name: string
  slug: string
  image: {
    fluid: FluidObject
  }
}

type Props = {
  category: Category
  categoryPath: string
}
const Section: React.FC<Props> = props => {
  return (
    <Link to={`/${props.categoryPath}/${props.category.slug}`}>
      <div className="h-24 rounded-md shadow-md relative overflow-hidden">
        <Img fluid={props.category.image.fluid} className="h-24 blur-sm" />
        <div className="absolute top-0 left-0 bg-primary-400 h-full w-full opacity-25"></div>
        <p className="absolute absolute-center w-full text-center font-black text-gray-100 uppercase text-sm lg:text-2xl">
          {props.category.name}
        </p>
      </div>
    </Link>
  )
}

const Sections = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCategory {
        edges {
          node {
            slug
            name
            image {
              fluid(cropFocus: CENTER, maxWidth: 750) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          prefixes {
            category
          }
        }
      }
    }
  `)
  const categories = data.allContentfulCategory.edges
  return (
    <div className="min-h-64 pattern bg-repeat bg-sm bg-pink-100 p-2 md:p-10">
      <div className="bg-gray-100 h-full grid grid-cols-2 md:grid-cols-3 gap-4 p-4 md:gap-8 md:p-8 rounded-md">
        {categories.map((item: { node: Category }, i: number) => (
          <Section
            category={{ ...item.node }}
            key={i}
            categoryPath={data.site.siteMetadata.prefixes.category}
          />
        ))}
      </div>
    </div>
  )
}

export default Sections
