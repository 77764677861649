import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero-image-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="relative overflow-hidden">
      <div className="absolute h-full w-full bg-pink-800 z-10 opacity-25"></div>
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        className="h-96 blur"
      />
      <div className="absolute absolute-center w-full z-20">
        <p className="text-center text-white text-4xl md:text-6xl uppercase font-black">
          Tooth Club
        </p>
        <p className="text-center text-white text-sm md:text-xl uppercase">
          Lorem ipsum dolor sit amet amarebai suramet epat
        </p>
      </div>
    </div>
  )
}

export default Hero
